const DataType = {
  GrantTypeEnum: {
    EMPLOYEE: { value: 1, text: 'EMPLOYEE' },
    CUSTOMER: { value: 2, text: 'CUSTOMER' }
  },
  AjaxResultCodeEnum: {
    CREDENTIAL_IS_INVALID: { value: -79050001, text: 'credential is invalid' },
    ACCOUNT_IS_INVALID: { value: -79050002, text: 'account information is invalid' },
    MISSING_IDENTITY_INFORMATION: { value: -79050003, text: 'Missing identity information' },
    WRONG_GRANT_TYPE: { value: -79050004, text: 'Wrong grant type' },
    NO_LONGER_ORIGINATING_LOANS: { value: -83131201, text: 'This portfolio is no longer originating new loans' }
  },
  CustomerContactTypeEnum: {
    HOME_PHONE: { value: 1, text: 'Home Phone', fieldKey: 'homePhone' },
    CELL_PHONE: { value: 2, text: 'Cell Phone', fieldKey: 'cellPhone' },
    EMAIL: { value: 3, text: 'Email', fieldKey: 'email' },
    ALTERNATIVE_PHONE: { value: 4, text: 'Alternative Phone', fieldKey: 'alternativePhone' },
    ALTERNATIVE_EMAIL: { value: 5, text: 'Alternative Email', fieldKey: 'alternativeEmail' }
  },
  PurposeConst: {
    CREATING_EMAIL_CONFIRMATION: 1,
    RESETTING_PASSWORD: 2,
    CHANGING_EMAIL_CONFIRMATION: 3
  },
  IbvPlatform: {
    DECISION_LOGIC: 1
  },
  OperationConst: {
    CREATE_ACCOUNT: 'Creating Account',
    RESET_PASSWORD: 'Resetting Password',
    VERIFY_EMAIL: 'Verifying Email'
  },
  PayrollTypeEnum: {
    DIRECT_DEPOSIT: { value: 18000, text: 'Direct Deposit' },
    PAPER_CHECK: { value: 18001, text: 'Paper Check' },
    CASH: { value: 18002, text: 'Cash' },
    UNEMPLOYED_INSURANCE: { value: 18003, text: 'Unemployed Insurance' }
  },
  PaydayOnHolidayType: [
    { value: -1, text: 'Previous Business Day' },
    { value: 1, text: 'Next Business Day' }
  ],
  ContactTypeEnum: {
    HOME_PHONE: { value: 1, text: 'Home Phone', key: 'homePhone', verifyKey: 'homePhoneVerify' },
    CELL_PHONE: { value: 2, text: 'Cell Phone', key: 'cellPhone', verifyKey: 'cellPhoneVerify' },
    EMAIL: { value: 3, text: 'Email', key: 'email', verifyKey: 'emailVerify' },
    ALTERNATIVE_PHONE: { value: 4, text: 'Alternative Phone', key: 'alternativePhone', verifyKey: 'alternativePhoneVerify' },
    ALTERNATIVE_EMAIL: { value: 5, text: 'Alternative Email', key: 'alternativeEmail', verifyKey: 'alternativeEmailVerify' }
  },
  StateEnum: {
    AL: { value: 'AL', text: 'AL - Alabama' },
    AK: { value: 'AK', text: 'AK - Alaska' },
    AZ: { value: 'AZ', text: 'AZ - Arizona' },
    AR: { value: 'AR', text: 'AR - Arkansas' },
    CA: { value: 'CA', text: 'CA - California' },
    CO: { value: 'CO', text: 'CO - Colorado' },
    CT: { value: 'CT', text: 'CT - Connecticut' },
    DE: { value: 'DE', text: 'DE - Delaware' },
    DC: { value: 'DC', text: 'DC - District of Columbia' },
    FL: { value: 'FL', text: 'FL - Florida' },
    GA: { value: 'GA', text: 'GA - Georgia' },
    HI: { value: 'HI', text: 'HI - Hawaii' },
    ID: { value: 'ID', text: 'ID - Idaho' },
    IL: { value: 'IL', text: 'IL - Illinois' },
    IN: { value: 'IN', text: 'IN - Indiana' },
    IA: { value: 'IA', text: 'IA - Iowa' },
    KS: { value: 'KS', text: 'KS - Kansas' },
    KY: { value: 'KY', text: 'KY - Kentucky' },
    LA: { value: 'LA', text: 'LA - Louisiana' },
    ME: { value: 'ME', text: 'ME - Maine' },
    MD: { value: 'MD', text: 'MD - Maryland' },
    MA: { value: 'MA', text: 'MA - Massachusetts' },
    MI: { value: 'MI', text: 'MI - Michigan' },
    MN: { value: 'MN', text: 'MN - Minnesota' },
    MS: { value: 'MS', text: 'MS - Mississippi' },
    MO: { value: 'MO', text: 'MO - Missouri' },
    MT: { value: 'MT', text: 'MT - Montana' },
    NE: { value: 'NE', text: 'NE - Nebraska' },
    NV: { value: 'NV', text: 'NV - Nevada' },
    NH: { value: 'NH', text: 'NH - New Hampshire' },
    NJ: { value: 'NJ', text: 'NJ - New Jersey' },
    NM: { value: 'NM', text: 'NM - New Mexico' },
    NY: { value: 'NY', text: 'NY - New York' },
    NC: { value: 'NC', text: 'NC - North Carolina' },
    ND: { value: 'ND', text: 'ND - North Dakota' },
    OH: { value: 'OH', text: 'OH - Ohio' },
    OK: { value: 'OK', text: 'OK - Oklahoma' },
    OR: { value: 'OR', text: 'OR - Oregon' },
    PA: { value: 'PA', text: 'PA - Pennsylvania' },
    PR: { value: 'PR', text: 'PR - Puerto Rico' },
    RI: { value: 'RI', text: 'RI - Rhode Island' },
    SC: { value: 'SC', text: 'SC - South Carolina' },
    SD: { value: 'SD', text: 'SD - South Dakota' },
    TN: { value: 'TN', text: 'TN - Tennessee' },
    TX: { value: 'TX', text: 'TX - Texas' },
    UT: { value: 'UT', text: 'UT - Utah' },
    VT: { value: 'VT', text: 'VT - Vermont' },
    VA: { value: 'VA', text: 'VA - Virginia' },
    WA: { value: 'WA', text: 'WA - Washington' },
    WV: { value: 'WV', text: 'WV - West Virginia' },
    WI: { value: 'WI', text: 'WI - Wisconsin' },
    WY: { value: 'WY', text: 'WY - Wyoming' }
  },
  IncomeTypeEnum: {
    DISABILITY_INCOME: { value: 7000, text: 'Disability Income' },
    SOCIAL_SECURITY: { value: 7001, text: 'Social Security' },
    EMPLOYED: { value: 7002, text: 'Employed' },
    UNEMPLOYMENT: { value: 7003, text: 'Unemployment' },
    PENSION: { value: 7004, text: 'Pension' },
    OTHERS: { value: 7005, text: 'Others (Child Support, Welfare etc.) ' }
  },
  PayrollFrequency: [
    { value: 17003, text: 'Weekly', period: 7, count: 52, code: 'W' },
    { value: 17000, text: 'Bi-Weekly', period: 14, count: 26, code: 'B' },
    { value: 17002, text: 'Semi-Monthly', period: 15, count: 24, code: 'S' },
    { value: 17001, text: 'Monthly', period: 30, count: 12, code: 'M' }
  ],
  PaymentFrequency: [
    { value: 17003, text: 'Weekly', period: 7, count: 52, code: 'W' },
    { value: 17000, text: 'Bi-Weekly', period: 14, count: 26, code: 'B' }
  ],
  PayrollFrequencyEnum: {
    WEEKLY: { value: 17003, text: 'Weekly', period: 7, count: 52, code: 'W' },
    BI_WEEKLY: { value: 17000, text: 'Bi-Weekly', period: 14, count: 26, code: 'B' },
    SEMI_MONTHLY: { value: 17002, text: 'Semi-Monthly', period: 15, count: 24, code: 'S' },
    MONTHLY: { value: 17001, text: 'Monthly', period: 30, count: 12, code: 'M' }
  },
  BankAccountTypeEnum: {
    CHECKING_ACCOUNT: { value: 3001, text: 'Checking Account', code: 'C' },
    SAVINGS_ACCOUNT: { value: 3000, text: 'Savings Account', code: 'S' },
    MONEY_MARKET: { value: 3002, text: 'Money Market', code: 'M' }
  },
  PaydayOnHolidayEnum: {
    PREVIOUS_DAY: { value: -1, text: 'Previous Business Day' },
    NEXT_DAY: { value: 1, text: 'Next Business Day' }
  },
  PaydayOnAvailableEnum: {
    SAME_DAY: { value: 0, text: 'Same Day' },
    NEXT_BUSINESS_DAY: { value: 1, text: 'Next Business Day' }
  },
  PaymentModeEnum: {
    ACH: { value: 15000, text: 'ACH' },
    CASH: { value: 15001, text: 'Cash' },
    CASHIER_CHECK: { value: 15002, text: 'Cashier check' },
    CREDIT_CARD: { value: 15003, text: 'Credit Card' },
    DEBIT_CARD: { value: 15004, text: 'Debit Card' },
    MONEY_GRAM: { value: 15005, text: 'Money Gram' },
    MONEY_ORDER: { value: 15006, text: 'Money Order' },
    PERSONAL_CHECK: { value: 15007, text: 'Personal Check' },
    WESTERN_UNION: { value: 15008, text: 'Western Union' },
    WIRE: { value: 15009, text: 'Wire' },
    LOAN_TRANSFER: { value: 15010, text: 'Loan Transfer' },
    NONE: { value: 15011, text: 'None' },
    WAGE_ASSIGMENT: { value: 15012, text: 'Wage Assigment' },
    INSTANT_FUND: { value: 15014, text: 'Instant Fund' }
  },
  JobTypeEnum: {
    FULL_TIME: { value: 1000, text: 'Full Time' },
    PART_TIME: { value: 1001, text: 'Part Time' }
  },
  DayOfWeekPaidEnum: {
    MONDAY: { value: 2, text: 'Monday' },
    TUESDAY: { value: 3, text: 'Tuesday' },
    WEDNESDAY: { value: 4, text: 'Wednesday' },
    THURSDAY: { value: 5, text: 'Thursday' },
    Friday: { value: 6, text: 'Friday' }
  },
  DayOfSemiMonthEnum: {
    LAST_DAY_OF_MONTH: { value: -1, text: 'Last day of month' },
    ONE: { value: 1, text: '1' },
    TWO: { value: 2, text: '2' },
    THREE: { value: 3, text: '3' },
    FOUR: { value: 4, text: '4' },
    FIVE: { value: 5, text: '5' },
    SIX: { value: 6, text: '6' },
    SEVEN: { value: 7, text: '7' },
    EIGHT: { value: 8, text: '8' },
    NINE: { value: 9, text: '9' },
    TEN: { value: 10, text: '10' },
    ELEVEN: { value: 11, text: '11' },
    TWELVE: { value: 12, text: '12' },
    THIRTEEN: { value: 13, text: '13' },
    FOURTEEN: { value: 14, text: '14' },
    FIFTEEN: { value: 15, text: '15' },
    SIXTEEN: { value: 16, text: '16' },
    SEVENTEEN: { value: 17, text: '17' },
    EIGHTEEN: { value: 18, text: '18' },
    NINETEEN: { value: 19, text: '19' },
    TWENTY: { value: 20, text: '20' },
    TWENTY_ONE: { value: 21, text: '21' },
    TWENTY_TWO: { value: 22, text: '22' },
    TWENTY_THREE: { value: 23, text: '23' },
    TWENTY_FOUR: { value: 24, text: '24' },
    TWENTY_FIVE: { value: 25, text: '25' },
    TWENTY_SIX: { value: 26, text: '26' },
    TWENTY_SEVEN: { value: 27, text: '27' },
    TWENTY_EIGHT: { value: 28, text: '28' },
    TWENTY_NINE: { value: 29, text: '29' },
    THIRTY: { value: 30, text: '30' },
    THIRTY_ONE: { value: 31, text: '31' }
  },
  DayOfMonthEnum: [
    { value: -11, text: 'Last B-Day Of Month' },
    { value: -12, text: 'Mid Of Month' },
    { value: -13, text: 'First B-Day Of Month' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
    { value: 12, text: '12' },
    { value: 13, text: '13' },
    { value: 14, text: '14' },
    { value: 15, text: '15' },
    { value: 16, text: '16' },
    { value: 17, text: '17' },
    { value: 18, text: '18' },
    { value: 19, text: '19' },
    { value: 20, text: '20' },
    { value: 21, text: '21' },
    { value: 22, text: '22' },
    { value: 23, text: '23' },
    { value: 24, text: '24' },
    { value: 25, text: '25' },
    { value: 26, text: '26' },
    { value: 27, text: '27' },
    { value: 28, text: '28' },
    { value: 29, text: '29' },
    { value: 30, text: '30' },
    { value: 31, text: '31' }
  ],
  IbvPlatformConst: {
    DECISION_LOGIC: 1
  },
  StatusEnum: {
    UNKNOWN: { value: 0, text: 'Unknown' },
    ENABLE: { value: 1, text: 'Enable' },
    DISABLE: { value: -1, text: 'Disable' }
  },
  PhoenixActionEnum: {
    SIGNATURE_CALLBACK: { value: 3001, text: 'Signature Callback' },
    CUT_OFF_TIME_EXPIRED: { value: 3002, text: 'Cut Off Time Expired' },
    IN_PROGRESS: { value: 3003, text: 'IN Progress' }
  },
  SignStatusEnum: {
    NOT_SIGNED: { value: 0, text: 'Not Signed' },
    SIGNED: { value: 1, text: 'Signed' },
    EXPIRED: { value: 2, text: 'Expired' },
    CANCELLED: { value: 3, text: 'Canceled' },
    VOIDED: { value: 4, text: 'Voided' },
    SUSPENDED: { value: 5, text: 'Suspended' }
  },
  DocumentTypeEnum: {
    IDENTIFY_DOCUMENT: { value: 1, text: 'Identify document' },
    BANK_DOCUMENT: { value: 2, text: 'Bank document' },
    OTHERS_DOCUMENT: { value: 3, text: 'Others document' },
    LOAN_AGREEMENT: { value: 4, text: 'Loan Agreement' },
    CERTIFICATE_OF_EVIDENCE: { value: 5, text: 'Certificate of Evidence' },
    BANKRUPTCY: { value: 6, text: 'Bankruptcy' },
    CCCS: { value: 7, text: 'Cccs' },
    PAPER_CHECK: { value: 8, text: 'Paper Check' },
    EMAIL_ATTACHMENTS: { value: 9, text: 'Email Attachments' },
    POA: { value: 10, text: 'POA' },
    ATTY_REP_EMAIL: { value: 11, text: 'Atty Rep Email' },
    MONEY_ORDER_ATTACHMENTS: { value: 12, text: 'Money Order Attachments' },
    ONLINE_VERIFICATION_OF_EMPLOYMENT: { value: 13, text: 'Online Verification of Employment' },
    SCRA: { value: 14, text: 'SCRA' },
    SSN: { value: 100, text: 'SSN' },
    DRIVER_LICENSE: { value: 101, text: 'Driver License' },
    GRADUATION_CERTIFICATE: { value: 102, text: 'Graduation Certificate' }
  },
  CustomerOptInEnum: {
    DO_NOT_CALL: { value: 2, text: 'Do Not Call' },
    UN_CONTACTABLE: { value: 4, text: 'Un Contactable' },
    IS_MARKETING: { value: 8, text: 'Is Marketing' },
    IS_OPERATION: { value: 16, text: 'Is Operation' }
  },
  ChangeTypeEnum: {
    NORMAL: { value: 0, text: 'Normal' },
    PAY_OFF: { value: 101, text: 'Pay Off' },
    PAY_OFF_IN_N: { value: 102, text: 'Pay Off in # Payments' },
    PAY_DOWN: { value: 103, text: 'Pay Down' },
    REVOKE_FUTURE_ACH: { value: 104, text: 'Revoke Future ACH' },
    CHANGE_PAYROLL_DATE: { value: 105, text: 'Change Payroll Date' },
    CHANGE_PAYROLL_FREQUENCY: { value: 106, text: 'Change Payroll Frequency' },
    CHANGE_PAYMENT_MODE: { value: 107, text: 'Change Payment Mode' },
    CHANGE_PAYDAY_ON_HOLIDAY: { value: 108, text: 'Change Payday On Holiday' },
    CHANGE_PAYDAY_ON_AVAILABLE: { value: 109, text: 'Change Payday On Available' },
    // REVOKE_WA: { value: 110, text: 'Revoke WA' },
    PAY_FIXED_AMOUNT: { value: 111, text: 'Pay Fixed Amount' },
    ASSIGNED_SKIP: { value: 201, text: 'Skip Payment' },
    ASSIGNED_MAKEUP: { value: 202, text: 'Makeup Full Payment' },
    ASSIGNED_AMOUNT: { value: 203, text: 'Change Payment Amount' },
    ASSIGNED_MODE: { value: 204, text: 'Change Payment Mode' },
    ASSIGNED_DATE: { value: 205, text: 'Change Payment Date' },
    ASSIGNED_MAKEUP_PARTIAL: { value: 206, text: 'Make Up Partial Payment' },
    REFUND_CREDIT: { value: 301, text: 'Reverse Credit' },
    REVERSE_DEBIT: { value: 302, text: 'Reverse Debit' },
    REFUND_BANK_FEE: { value: 303, text: 'Refund Bank Fee' },
    REFUND_FULL_PAYMENT: { value: 304, text: 'Refund Full Payment' },
    REFUND_PARTIAL_PAYMENT: { value: 305, text: 'Refund Partial Payment' },
    REFUND_PAYMENT: { value: 306, text: 'Refund Payment' },
    MANUAL_REJECT: { value: 901, text: 'Manual Reject' },
    CANCEL_CHANGE: { value: 905, text: 'Cancel Changes' },
    MANUAL_CHECKED: { value: 903, text: 'Manual Checked' }
  },
  TransactionTypeEnum: {
    CREDIT: { value: '14000', text: 'Credit' },
    DEBIT: { value: '14001', text: 'Debit' },
    REFUND: { value: '14002', text: 'Refund' }
  },
  TransactionStatusEnum: {
    NONE: { value: 0, text: 'None' },
    PENDING: { value: 1, text: 'Pending' },
    NOT_CHECKED: { value: 2, text: 'Not Checked' },
    CHECKED: { value: 3, text: 'Checked' },
    ON_BATCH: { value: 4, text: 'On Batch' },
    REJECTED: { value: -1, text: 'Rejected' },
    PAST_DUE: { value: -2, text: 'past due' }
  },
  TransactionModeEnum: {
    ACH: { value: 15000, text: 'ACH' },
    PAPER_CHECK: { value: 15002, text: 'Paper Check' },
    NONE: { value: 15011, text: 'None' },
    RTT: { value: 15014, text: 'RTT' },
    TPT: { value: 15016, text: 'TPT' },
    RCC: { value: 15018, text: 'RCC' }
  },
  MultiActionRequestEnum: {
    PREVIEW: { value: 0, text: 'Preview' },
    SAVE: { value: 1, text: 'Save' }
  },
  InstallmentFlagsEnum: {
    DEFAULT: 0,
    DELETED: 1 << 0,
    FULL_REFUNDED: 1 << 1,
    CANCEL: 1 << 2,
    COLLECTION: 1 << 3,
    PARTIAL_REFUND: 1 << 4,
    NEW: 1 << 8
  },
  HomePageFlags: {
    NO_LOAN_AMOUNT: 1,
    PREPARE_APPLICATION: 2,
    UNSIGNED: 3,
    IN_PROGRESS: 4,
    REPAYMENT_LOAN: 5,
    REPAIR_INFORMATION: 6
  },
  InProgressEnum: {
    APPLICATION_SUBMISSION: { value: 1, text: 'Application Submitted' },
    PRE_UNDERWRITER_REVIEW: { value: 2, text: 'Pre-Underwriter Review' },
    FINAL_APPROVAL: { value: 3, text: 'Application Approved' },
    DISBURSEMENT_IN_PROGRESS: { value: 4, text: 'Funding In Progress' },
    DISBURSEMENT_COMPLETE: { value: 5, text: 'Funding Completed' },
    Exception: { value: 6, text: 'In Progress Exception' }
  },
  DayOfMonth31: [
    { value: -1, text: 'Last day of month' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
    { value: 12, text: '12' },
    { value: 13, text: '13' },
    { value: 14, text: '14' },
    { value: 15, text: '15' },
    { value: 16, text: '16' },
    { value: 17, text: '17' },
    { value: 18, text: '18' },
    { value: 19, text: '19' },
    { value: 20, text: '20' },
    { value: 21, text: '21' },
    { value: 22, text: '22' },
    { value: 23, text: '23' },
    { value: 24, text: '24' },
    { value: 25, text: '25' },
    { value: 26, text: '26' },
    { value: 27, text: '27' },
    { value: 28, text: '28' },
    { value: 29, text: '29' },
    { value: 30, text: '30' },
    { value: 31, text: '31' }
  ],
  TransactionTypeOptions: [
    { value: '14000', text: 'Credit' },
    { value: '14001', text: 'Debit' },
    { value: '14002', text: 'Refund' }
  ],
  DayOfMonth: [
    { value: -11, text: 'Last B-Day Of Month' },
    { value: -12, text: 'Mid Of Month' },
    { value: -13, text: 'First B-Day Of Month' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
    { value: 12, text: '12' },
    { value: 13, text: '13' },
    { value: 14, text: '14' },
    { value: 15, text: '15' },
    { value: 16, text: '16' },
    { value: 17, text: '17' },
    { value: 18, text: '18' },
    { value: 19, text: '19' },
    { value: 20, text: '20' },
    { value: 21, text: '21' },
    { value: 22, text: '22' },
    { value: 23, text: '23' },
    { value: 24, text: '24' },
    { value: 25, text: '25' },
    { value: 26, text: '26' },
    { value: 27, text: '27' },
    { value: 28, text: '28' },
    { value: 29, text: '29' },
    { value: 30, text: '30' },
    { value: 31, text: '31' }
  ],
  DayOfSemiMonth: [
    { value: -1, text: 'Last day of month' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
    { value: 12, text: '12' },
    { value: 13, text: '13' },
    { value: 14, text: '14' },
    { value: 15, text: '15' },
    { value: 16, text: '16' },
    { value: 17, text: '17' },
    { value: 18, text: '18' },
    { value: 19, text: '19' },
    { value: 20, text: '20' },
    { value: 21, text: '21' },
    { value: 22, text: '22' },
    { value: 23, text: '23' },
    { value: 24, text: '24' },
    { value: 25, text: '25' },
    { value: 26, text: '26' },
    { value: 27, text: '27' },
    { value: 28, text: '28' },
    { value: 29, text: '29' },
    { value: 30, text: '30' },
    { value: 31, text: '31' }
  ],
  NotificationTypeEnum: {
    SES_MESSAGE: { value: 1, text: 'SES_MESSAGE' },
    SMS_MESSAGE: { value: 2, text: 'SMS_MESSAGE' }
  },
  EventTypeEnum: {
    ENTER_PAGE: { value: 1001, text: 'Enter Page' },
    LEAVE_PAGE: { value: 1002, text: 'Leave Page' },
    ACCESS_API: { value: 1003, text: 'Interface Request' },
    UI_INTERACTION: { value: 1004, text: 'UI Interaction' }
  },
  LastPaymentDate: {
    LAST_DEBIT_DATE: { value: -1, text: 'Last Debit Date' },
    CONTRACT_DEADLINE: { value: 1, text: 'Contract Deadline' }
  },
  EventSourceEnum: {
    // ACCOUNT_ENTER_LEAVE
    ACCOUNT_ENTER_LEAVE_ACCEPT_CONSENTS: { value: 110101001, text: 'Accept Consents Page' },
    ACCOUNT_ENTER_LEAVE_CREATE_ACCOUNT: { value: 110101002, text: 'Create Account Page' },
    ACCOUNT_ENTER_LEAVE_CREATE_ACCOUNT_ID: { value: 110101003, text: 'Create Account ID Page' },
    ACCOUNT_ENTER_LEAVE_CREATE_ACCOUNT_PASSWORD: { value: 110101004, text: 'Create Account Password Page' },
    ACCOUNT_ENTER_LEAVE_WELCOME: { value: 110101005, text: 'Welcome Page' },
    // ACCOUNT_API
    ACCOUNT_API_ACCEPT_CONSENTS: { value: 110301001, text: 'Accept Consents' },
    ACCOUNT_API_CREATE_ACCOUNT: { value: 110301002, text: 'Create Account' },
    ACCOUNT_API_CREATE_ACCOUNT_ID: { value: 110301003, text: 'Create Account ID' },
    ACCOUNT_API_CREATE_ACCOUNT_PASSWORD: { value: 110301004, text: 'Create Account Password' },
    // guidance enter and leave
    GUIDANCE_ENTER_LEAVE_PERSONAL_INFORMATION: { value: 120101001, text: 'Personal Information' },
    GUIDANCE_ENTER_LEAVE_EMPLOYMENT_INFORMATION: { value: 120101002, text: 'Employment Information' },
    GUIDANCE_ENTER_LEAVE_BANK_INFORMATION: { value: 120101003, text: 'Bank Information' },
    GUIDANCE_ENTER_LEAVE_FAST_PASS: { value: 120101004, text: 'Fast Pass Guidance' },
    GUIDANCE_ENTER_LEAVE_DL: { value: 120102001, text: 'IBV Introduction' },
    GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION: { value: 120102002, text: 'IBV Authorization' },
    GUIDANCE_ENTER_LEAVE_LOAN_AMOUNT: { value: 120102003, text: 'Calculate Loan Amount' },
    GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION_IFRAME: { value: 120102004, text: 'IBV Authorization Iframe' },
    // Guidance API
    GUIDANCE_API_CONFIRM_PERSONAL_INFORMATION: { value: 120301001, text: 'Confirm Personal Information' },
    GUIDANCE_API_CONFIRM_EMPLOYMENT_INFORMATION: { value: 120301002, text: 'Confirm Employment Information' },
    GUIDANCE_API_CONFIRM_BANK_INFORMATION: { value: 120301003, text: 'Confirm Bank Information' },
    GUIDANCE_API_CONFIRM_PAYROLL_INFORMATION: { value: 120301004, text: 'Confirm Payroll Information' },
    GUIDANCE_API_DL_CREATE_REQUEST_CODE: { value: 120302001, text: 'Create Request Code' },
    GUIDANCE_API_DL_VERIFIED: { value: 120302002, text: 'Request Code Verified' },
    GUIDANCE_API_DL_GET_MULTIPLE_REPORTS: { value: 120302003, text: 'Get Multiple Reports' },
    GUIDANCE_API_CALC_LOAN_AMOUNT: { value: 120302004, text: 'Calculate Loan Amount' },
    // ME_ENTER_LEAVE
    ME_ENTER_LEAVE_PERSONAL_INFORMATION: { value: 130101001, text: 'Personal Page' },
    ME_ENTER_LEAVE_CONTACT_INFORMATION: { value: 130101002, text: 'Contact Page' },
    ME_ENTER_LEAVE_EMPLOYMENT_INFORMATION: { value: 130101003, text: 'Employment Page' },
    ME_ENTER_LEAVE_PAYROLL_INFORMATION: { value: 130101004, text: 'Payroll Page' },
    ME_ENTER_LEAVE_BANK_INFORMATION: { value: 130101005, text: 'Bank Page' },
    ME_ENTER_LEAVE_DOCUMENTS: { value: 130101006, text: 'Documents Page' },
    ME_ENTER_LEAVE_OPT_IN: { value: 130101007, text: 'Opt In Page' },
    ME_ENTER_LEAVE_CHANGE_PASSWORD: { value: 130101008, text: 'Change Password Page' },
    // ME_API
    ME_API_CONFIRM_PERSONAL_INFORMATION: { value: 130301001, text: 'Personal Save' },
    ME_API_CONFIRM_CONTACT_INFORMATION: { value: 130301002, text: 'Contact Submit' },
    ME_API_CONFIRM_EMPLOYMENT_INFORMATION: { value: 130301003, text: 'Employment Save' },
    ME_API_CONFIRM_PAYROLL_INFORMATION: { value: 130301004, text: 'payroll Save' },
    ME_API_CONFIRM_BANK_INFORMATION: { value: 130301005, text: 'Bank Save' },
    ME_API_CHANGE_PASSWORD: { value: 130301006, text: 'Change Password Save' },
    // pre-origination
    PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_LOAN_AMOUNT: { value: 140101001, text: 'Choose Loan Amount' },
    PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_PAYMENT_MODE: { value: 140101002, text: 'Choose Payment Mode' },
    PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_DATE_BANK: { value: 140101003, text: 'Choose Date Bank' },
    PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_LOAN_TERM: { value: 140101004, text: 'Choose Loan Term' },
    PRE_ORIGINATION_ENTER_LEAVE_CONFIRM_LOAN_DETAILS: { value: 140101005, text: 'Confirm Loan Details' },
    PRE_ORIGINATION_ENTER_LEAVE_SIGN_LOAN: { value: 140101006, text: 'Sign Loan' },
    PRE_ORIGINATION_ENTER_LEAVE_NO_LOAN_AMOUNT: { value: 140101007, text: 'No Loan Amount' },
    PRE_ORIGINATION_API_CREATE_LOAN: { value: 140301001, text: 'Pre-origination Create Loan' },
    PRE_ORIGINATION_API_CALCULATE_PAYMENT_SCHEDULE: { value: 140301002, text: 'Pre-origination Calculate Payment Schedule' },
    PRE_ORIGINATION_API_SIGN_LOAN: { value: 140301003, text: 'Pre-origination Sign Loan' },
    // post-origination
    POST_ORIGINATION_ENTER_LEAVE_PAY_OFF: { value: 150101001, text: 'Pay Off' },
    POST_ORIGINATION_ENTER_LEAVE_PAY_OFF_IN_N: { value: 150101002, text: 'Pay Off in # Payments' },
    POST_ORIGINATION_ENTER_LEAVE_PAY_DOWN: { value: 150101003, text: 'Pay Down' },
    POST_ORIGINATION_ENTER_LEAVE_CHANGE_PAYROLL_FREQUENCY: { value: 150101004, text: 'Change Payroll Frequency' },
    POST_ORIGINATION_ENTER_LEAVE_CHANGE_PAYROLL_DATE: { value: 150101005, text: 'Change Payroll Date' },
    POST_ORIGINATION_ENTER_LEAVE_REVOKE_FUTURE_ACH: { value: 150101006, text: 'Revoke Future ACH' },
    POST_ORIGINATION_ENTER_LEAVE_PAY_FIXED_AMOUNT: { value: 150101007, text: 'Pay Fixed Amount' },
    POST_ORIGINATION_ENTER_LEAVE_CHANGE_PAYMENT_MODE: { value: 150101008, text: 'Change Payment Mode' },
    // POST_ORIGINATION_ENTER_LEAVE_REVOKE_WA: { value: 150101009, text: 'Revoke WA' },
    POST_ORIGINATION_LOAN_DETAILS: { value: 150101010, text: 'Post-Origination Loan Details' },
    POST_ORIGINATION_ENTER_LEAVE_LOAN_RECORDS: { value: 150101011, text: 'Post-Origination Loan Records' },
    POST_ORIGINATION_API_PAY_OFF: { value: 150301001, text: 'Post-origination Pay Off' },
    POST_ORIGINATION_API_PAY_OFF_IN_N: { value: 150301002, text: 'Post-origination Pay Off In N' },
    POST_ORIGINATION_API_PAY_DOWN: { value: 150301003, text: 'Post-origination Pay Down' },
    POST_ORIGINATION_API_CHANGE_PAYROLL_FREQUENCY: { value: 150301004, text: 'Post-origination Change Payroll Frequency' },
    POST_ORIGINATION_API_CHANGE_PAYROLL_DATE: { value: 150301005, text: 'Post-origination Change Payroll Date' },
    POST_ORIGINATION_API_REVOKE_FUTURE_ACH: { value: 150301006, text: 'Post-origination Revoke Future ACH' },
    POST_ORIGINATION_API_PAY_FIXED_AMOUNT: { value: 150301007, text: 'Post-origination Pay Fixed Amount' },
    POST_ORIGINATION_API_CHANGE_PAYMENT_MODE: { value: 150301008, text: 'Post-origination Change Payment Mode' }
  },
  UserDeviceEnum: {
    WEB: { value: 2001, text: 'Web' },
    WAP: { value: 2002, text: 'Web' }
  },
  SystemCodeEnum: {
    PANDA: { value: 'D5129E286DB74E8A835F105C42201BB5', text: 'PANDA' },
    BAMBOO: { value: 'F86C0AD082584F4E88EB803EC7F66C69', text: 'BAMBOO' },
    ANT: { value: '9269495B27414B858D0FB36567B38EF4', text: 'ANT' },
    TORTOISE: { value: 'A2341A5F9C1544F4A91974E1C5C68EA3', text: 'TORTOISE' },
    FOX: { value: '75B0DD4B693D93190FDF23E48D262EB6', text: 'FOX' },
    ZEBRA: { value: 'A51054826BDE49B3966347DC6FF4B856', text: 'ZEBRA' },
    PHOENIX: { value: '17E646E2A8074596B8D92B70C767BA7E', text: 'PHOENIX' }
  },
  LOAN_FLAG_DATA: {
    RECREATE_REQUEST_CODE: {
      value: 10000,
      text: 'Recreate Request Code'
    },
    RECALCULATE_LOAN_AMOUNT: {
      value: 10001,
      text: 'Recalculate Loan Amount'
    },
    RECALCULATE_PAYMENT_PLAN: {
      value: 10002,
      text: 'Recalculate Payment Plan'
    },
    RESIGN_LA: {
      value: 10003,
      text: 'Re-Sign Loan Agreement'
    }
  },
  Level1: {
    ACCOUNT: { value: 11, text: 'Account' },
    GUIDANCE: { value: 12, text: 'Guidance' },
    ME: { value: 13, text: 'Me' },
    PRE_ORIGINATION: { value: 14, text: 'Pre-Origination' },
    POST_ORIGINATION: { value: 15, text: 'Post-Origination' }
  },
  Level2: {
    ENTER_AND_LEAVE_PAGE: { value: '01', text: 'Enter and leave page' },
    API: { value: '03', text: 'Api' }
  },
  Level3: {
    RESERVE: { value: '01', text: 'Reserve' },
    RELATED_OPERATION_OF_DL: { value: '02', text: 'Related operation of DL' },
    RELATED_OPERATION_OF_APPLY_FOR_NEW_LOAN: { value: '03', text: 'Related operation of Apply for new loan' }
  },
  CreateAccountType: {
    GUIDANCE: 1,
    HOME: 2,
    END: 3,
    SIGN_LA: 4
  },
  LoanAmountResultType: {
    AVAILABLE_LOAN_AMOUNT_WITH_MATCHED_ACCOUNT: { value: 1, text: 'Available loan amount with matched account' },
    AVAILABLE_LOAN_AMOUNT_WITH_UNMATCHED_ACCOUNT: { value: 2, text: 'Available loan amount with unmatched account' },
    UNAVAILABLE_LOAN_AMOUNT: { value: 3, text: 'Unavailable loan amount' }
  },
  SectionKeyConst: {
    SUMMARY: {
      text: 'SUMMARY',
      value: 1
    },
    PERSONAL: {
      text: 'PERSONAL',
      value: 2
    },
    EMPLOYMENT: {
      text: 'EMPLOYMENT',
      value: 3
    },
    BANK: {
      text: 'BANK',
      value: 4
    },
    QUESTION: {
      text: 'QUESTION',
      value: 5
    },
    IBV: {
      text: 'IBV',
      value: 6
    },
    LOAN_DETAIL: {
      text: 'LOAN_DETAIL',
      value: 7
    },
    PAYMENT_SCHEDULE: {
      text: 'PAYMENT_SCHEDULE',
      value: 8
    },
    PAYMENT_FEE: {
      text: 'PAYMENT_FEE',
      value: 9
    }
  },
  PAYMENT_ENUM: {
    PAY_OFF: { value: 'PAY_OFF', text: 'Pay Off' },
    PAY_OFF_IN_N_PAYMENT: { value: 'PAY_OFF_IN_N_PAYMENT', text: 'Pay Off in N Payments' },
    PAY_DOWN: { value: 'PAY_DOWN', text: 'Pay Down' }/*,
    CHANGE_PAYROLL_FREQUENCY: { value: 'CHANGE_PAYROLL_FREQUENCY', text: 'Change Payroll Frequency' },
    PAY_FIXED_AMOUNT: { value: 'PAY_FIXED_AMOUNT', text: 'Pay Fixed Amount' },
    CHANGE_PAYROLL_DATE: { value: 'CHANGE_PAYROLL_DATE', text: 'Change Payroll Date' },
    REVOKE_FUTURE_ACH: { value: 'REVOKE_FUTURE_ACH', text: 'Revoke Future ACH' },
    CHANGE_PAYMENT_MODE: { value: 'CHANGE_PAYMENT_MODE', text: 'Change Payment Mode' } */
  },
  FirstDayOfWeek: [
    { value: 2, text: 'Monday' },
    { value: 3, text: 'Tuesday' },
    { value: 4, text: 'Wednesday' },
    { value: 5, text: 'Thursday' },
    { value: 6, text: 'Friday' }
  ],
  PortfolioEnum: {
    // isInUse是给develop环境使用的
    // SAVED_BY_CASH: { value: 60, text: 'SBC', domain: 'savedbycash' },
    OTTER_LENDING: { value: 110, text: 'OL', domain: 'otterlending' },
    GRAY_FOX_LENDING: { value: 118, text: 'GFL' }
  },
  EnableApplicationEnum: {
    FALSE: { value: '-1', text: 'false' },
    TRUE: { value: '1', text: 'true' }
  },
  AccountStatusEnum: {
    ACTIVE_ACCOUNT: { value: 1, text: 'Account is active' },
    INACTIVE_ACCOUNT: { value: -1, text: 'Account is not active' },
    DUPLICATE_ACCOUNT: { value: -2, text: 'Email has been used' },
    FROZEN_ACCOUNT: { value: -3, text: 'Account is frozen' }
  },
  LoanStatus: {
    CUSTOMER_REVIEW: { value: 20001, text: 'Customer Review' },
    AGENT_REVIEW: { value: 20002, text: 'Agent Review' },
    UNDERWRITER_REVIEW: { value: 20004, text: 'Underwriter Review' },
    TRIBE_REVIEW: { value: 20005, text: 'Tribe Review' },
    CORRECTOR_REVIEW: { value: 20006, text: 'Corrector Review' }
  },
  IBVStatusEnum: {
    OTHER_ERROR: { value: -3, text: 'OTHER_ERROR' },
    FINANCIAL_INSTITUTION_ERROR: { value: -2, text: 'FINANCIAL_INSTITUTION_ERROR' },
    ACCOUNT_ERROR: { value: -1, text: 'ACCOUNT_ERROR' },
    NOT_STARTED: { value: 0, text: 'NOT_STARTED' },
    STARTED_NOT_COMPLETED: { value: 1, text: 'STARTED_NOT_COMPLETED' },
    LOGIN_VERIFIED: { value: 3, text: 'LOGIN_VERIFIED' },
    IN_PROGRESS: { value: 20, text: 'IN_PROGRESS' },
    SUCCESSFUL: { value: 100, text: 'SUCCESSFUL' }
  },
  CustomerCategoryEnum: {
    NEW: { value: 1, text: 'New Customer' },
    RETURNING: { value: 2, text: 'Returning Customer' }
  },
  MilitaryEnum: {
    MILITARY: { value: 1, text: 'Military' },
    UNKNOWN: { value: 0, text: 'Unknown' },
    NOT_MILITARY: { value: -1, text: 'Not Military' }
  },
  IbvProviderEnum: {
    DECISION_LOGIC: { value: 10000, text: 'Decision Logic' },
    CHIRP: { value: 20000, text: 'Chirp' }
  },
  LoanRiskReportEnum: {
    HIGH_RISK: { value: 1, text: 'High Risk' },
    NO_RISK: { value: 0, text: 'No Risk' },
    NO_REPORT: { value: -1, text: 'No Report' }
  },
  PayOffEnum: {
    ONE_TIME_PAYMENT: { value: 510, text: 'One Time Payment' },
    PAY_OFF_IN_N: { value: 102, text: 'Pay Off In N Payments' },
    PAY_FIXED_AMOUNT: { value: 111, text: 'Pay Fixed Amount' }
  },
  LoanStatusEnum: {
    INITIALIZING: { text: 'Initializing', value: 101 },
    ORIGINATING: { text: 'Originating', value: 102 },
    WITHDRAWN: { text: 'Withdrawn', value: 103 },
    APPROVED: { text: 'Approved', value: 104 },
    PENDING: { text: 'Pending', value: 201 },
    DISBURSED: { text: 'Disbursed', value: 202 },
    PROCESSING: { text: 'Processing', value: 203 },
    SUSPENDED: { text: 'Suspended', value: 204 },
    COLLECTING: { text: 'Collecting', value: 205 },
    TERMINATED: { text: 'Terminated', value: 206 }
  },
  LoanSubStatusEnum: {
    CUSTOMER_REVIEW: { value: 102001, text: 'Customer Review' },
    AGENT_REVIEW: { value: 102002, text: 'Agent Review' },
    UNDERWRITER_REVIEW: { value: 102003, text: 'Underwriter Review' },
    TRIBE_REVIEW: { value: 102004, text: 'Tribe Review' },
    AUTO_VALIDATION: { value: 102005, text: 'Auto Validation' },
    NON_VOICE_AGENT_REVIEW: { value: 102006, text: 'Non-Voice Agent Review' },
    BLOCKED: { value: 102008, text: 'Blocked' },
    BAD_STANDING: { value: 204001, text: 'Bad Standing' },
    HARD_RETURN: { value: 204002, text: 'Bad Standing' },
    CCCS: { value: 204003, text: 'CCCS' },
    BANKRUPTCY: { value: 204004, text: 'Bankruptcy' },
    NO_FUTURE_PAYMENT: { value: 204005, text: 'No Future Payment' },
    MISCELLANEOUS: { value: 204006, text: 'Miscellaneous' },
    ACTIVE_DUTY: { value: 204001, text: 'Active Duty' },
    SETTLED: { value: 205001, text: 'Settled' },
    NOTIFIED: { value: 205002, text: 'Notified' },
    POCKETED: { value: 205003, text: 'Pocketed' },
    ACCEPTED: { value: 205004, text: 'Accepted' },
    RE_PLANNED: { value: 205005, text: 'Re-Planned' },
    REJECTED: { value: 205006, text: 'Rejected' },
    CLEARED: { value: 205007, text: 'Cleared' },
    VOIDED: { value: 206001, text: 'Voided' },
    PAID_OFF: { value: 206002, text: 'Paid Off' },
    CANCELLED: { value: 206003, text: 'Cancelled' }
  },
  NotificationPageType: {
    PENDING_NOTE: {
      value: 101,
      title: 'Congratulations',
      text: '',
      action: false,
      SVGControl: 'celebration'
    },
    APPLICATION_SUCCESS: {
      value: 102,
      title: 'Congratulations',
      text: '',
      action: false,
      SVGControl: 'celebration'
    },
    PAYMENT_SUCCESS: {
      value: 103,
      title: 'Congratulations',
      text: 'Your new payment plan has been confirmed.',
      action: false,
      SVGControl: 'celebration'
    },
    UPLOAD_BANK_STATEMENT_SUCCESS: {
      value: 104,
      title: 'Congratulations',
      text: 'Thank you for successfully uploading your bank statement! Your upload has been completed, and we have received the information you provided. We will now proceed to verify and process your statement. Kindly allow us some time to review the details. If we require any additional information or have any updates, we will promptly get in touch with you. We appreciate your patience and cooperation throughout this process. Thank you again!',
      action: false,
      SVGControl: 'celebration'
    },
    ORIGINATING_NOT_CUSTOMER_REVIEW: {
      value: 201,
      title: 'Processing',
      text: 'We received your application, which is now under careful review. You will be notified once a decision is made. Thank you for your patience.',
      action: false,
      SVGControl: 'exclamation'
    },
    NVR_CUSTOMER_PAGE: {
      value: 202,
      title: 'Processing',
      text: '',
      action: false,
      SVGControl: 'exclamation'
    },
    CALCULATE_NO_AMOUNT: {
      value: 203,
      title: 'Processing',
      text: '<div class="mb-2">After carefully reviewing your application, we regret to inform you that you do not meet our current criteria for credit approval. </div>' +
        '<div>If you want to use another bank account for your application, you can click the button below to try it again.</div>',
      action: true,
      SVGControl: 'exclamation'
    },
    PAPER_CHECK: {
      value: 204,
      title: 'Processing',
      text: 'We received your application, which is now under careful review. You will be notified once a decision is made. Thank you for your patience.',
      action: false,
      SVGControl: 'exclamation'
    },
    CANCEL_UPLOAD_STATEMENT: {
      value: 205,
      title: 'Processing',
      text: '',
      action: false,
      SVGControl: 'exclamation'
    },
    SOP: {
      value: 206,
      title: 'Processing',
      text: '',
      action: false,
      SVGControl: 'exclamation'
    },
    WITHDRAWN_NOTE: {
      value: 301,
      title: 'Unfortunately',
      text: 'After carefully reviewing your application, we regret to inform you that you do not meet our current criteria for credit approval. Thank you for thinking of us for your lending needs. Please don\'t hesitate to approach us in the future.',
      action: false,
      SVGControl: 'unfortunate'
    },
    INVALID_LINK: {
      value: 302,
      title: 'Unfortunately',
      text: '',
      action: false,
      SVGControl: 'unfortunate'
    }
  },
  PAYMENT_ACTION: {
    PREVIEW: { value: 0, text: 'Payment option preview' },
    SAVE: { value: 1, text: 'Save payment option' }
  },
  SIMULATOR_MODE: {
    DEMO: { value: true, text: 'Demo' },
    TEST: { value: false, text: 'Test' }
  },
  COUPON_EXIST: {
    NONE: { value: -1, text: 'Coupon not valid' },
    EXIST: { value: 1, text: 'Coupon has taken effect' }
  },
  ValidationRules: {
    EMAIL: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  },
  NOIA_SITUATION: {
    NOT_SUBMITTED: { value: 201, text: 'Continue to submit application' },
    NO_IBV: { value: 202, text: 'Continue to complete application' },
    NOT_VERIFIED: { value: 203, text: 'Two options in requirement page' },
    NOT_QUALIFIED: { value: 204, text: 'Change bank account and re-authorize IBV' }
  },
  SYSTEM_ERROR_CODE: {
    INVALID_LOAN: { value: -92020043, text: 'Loan status is invalid' },
    NO_IDENTITY: { value: -82030001, text: 'There is no identity information' },
    INVALID_IDENTITY: { value: -82030002, text: 'Token is outdated' }
  }
}

export default DataType

const state = {
  sessionId: '',
  openId: '',
  redirectUrlParameter: '',
  hasQualifiedPrincipal: false,
  consentPhone: ''
}

const getters = {
  getSessionId: state => state.sessionId,
  getOpenId: state => state.openId,
  getRedirectUrlParameter: state => state.redirectUrlParameter,
  getHasQualifiedPrincipal: state => state.hasQualifiedPrincipal,
  getConsentPhone: state => state.consentPhone
}

const actions = {}

const mutations = {
  setSessionId (state, sessionId) {
    state.sessionId = sessionId
  },
  setOpenId (state, openId) {
    state.openId = openId
  },
  setCreateAccountType (state, createAccountType) {
    state.createAccountType = createAccountType
  },
  setRedirectUrlParameter (state, data) {
    state.redirectUrlParameter = data
  },
  setHasQualifiedPrincipal (state, data) {
    state.hasQualifiedPrincipal = data
  },
  setConsentPhone (state, data) {
    state.consentPhone = data
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
